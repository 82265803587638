@import url('https://fonts.googleapis.com/css2?family=Oooh+Baby&family=Roboto:ital,wght@0,400;1,900&display=swap');

.root {
  display: flex;
  flex-direction: column;
  text-align: left;
  position: relative;
  max-width: 15rem;
  max-height: 15rem;
}

h3 {
  color: #fdd7f2;
  font-size: clamp(20px, 1.5vw, 40px)!important;
  font-style: italic;
  font-weight: 900;
}

p {
  color: rgba(255, 255, 255, 0.6);
  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
}

img, iframe, video {
  border-radius: 10px;
}

video {
  background-image: linear-gradient(60deg, rgb(61, 21, 44), rgb(25, 2, 10));
  border: 2px solid rgba(25, 16, 2, 1);
}

.playButton {
  width: 80px;
  height: 80px;
  position: absolute;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
  fill: rgb(225, 222, 225);
  // z-index: 31;
  mix-blend-mode: screen;
  filter: drop-shadow( 0px -5px 2px #e569a4) drop-shadow( 0px 0px 6px #d80752) drop-shadow( 3px 3px 3px #f37fc5) drop-shadow( 1px 1px 2px #e569a4) drop-shadow( 0px 4px 15px #180023);
}

.playButtonTriangle {
  fill: white;
  stroke: #E569A4;
  stroke-width: 2px;
}