@import url('https://fonts.googleapis.com/css2?family=Oooh+Baby&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@1,700&display=swap');

.root {
  position: absolute;
  width: 100%;
  bottom: 20%;
}


.logo-poradny {
  text-align: center;
  font-family: 'Oooh Baby', cursive;
  font-size: 130px;
  font-weight: 400;
  line-height: 0;
  color: #fdd7f2;
  text-shadow: 0px -5px 17px #e569a4, 0px 0px 33px #d80752, 6px 6px 12px #f37fc5,
    1px 4px 5px #e569a4, 0px 4px 60px #180023;
}
.logo-holky {
  text-align: center;
  margin-bottom: -107px;
  font-family: 'Roboto Condensed', sans-serif;
  background-color: #fff;
  color: #fff;
  font-size: 180px;
  font-style: italic;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 0;
  letter-spacing: -4px;
  text-shadow: -1px -4px 6px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width:768px) {
  
  .logo-poradny {
    font-size: 70px;
  }
  .logo-holky {
    font-size: 110px;
    margin-bottom: -60px;
  }
}
