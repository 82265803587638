.root {
  visibility: hidden;
  position: absolute;
  bottom: 75vh;
  left: -120vw;
  cursor: pointer;
  transition: left, visibility, 1s ease-in-out;
}

.back-button {
  fill: rgba(17, 0, 17, 0.97);
  box-shadow: 0px 0px 30px rgba(17, 0, 17, 1), 0px 0px 30px rgba(17, 0, 17, 1);

}

.circle {
  stroke: #f37fc5;
  stroke-width: 3px;
}

.arrow {
  fill: #f37fc5;
}

.visible {
  left: 4vw;
  visibility: visible;
  
}

@media (max-width:960px) {
  .root {
    position: fixed;
    left: -300vw;
    bottom: 0;
  }

  .visible {
    left: 0;
  }
}