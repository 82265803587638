.root {
    position: fixed;
    left: -3rem;
    transform-origin: bottom left;
    bottom: -2rem;
    width: 25vw;
}

img {
  width: 100%;
}

@media (max-width:768px) {
    .root {
      display: none;
    }
  }