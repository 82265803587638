.root {
    position: fixed;
    right: -2rem;
    transform-origin: bottom right;
    bottom: -2rem;
    width: 25vw;
}

img {
  width: 100%;
}


@media (max-width:768px) {
    .root {
      display: none;
    }
  }