.root {
  // visibility: visible;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  left: -120vw;
  border-radius: 20px;
  border: 2px solid rgba(253, 215, 242, 1);
  padding: 4% 6%;
  background-color: rgba(17, 0, 17, 0.97);
  transition: transform, visibility, 0.9s ease-in-out;
}

.visible {
  left: 0;
  visibility: visible;
  
}

h1 {
  font-size: clamp(2rem, 6vw, 6em);
  color: #fdd7f2;
  text-align: center;
  text-shadow: 0px -5px 17px rgba(229, 105, 164, 0.3), 0px 0px 33px rgba(216, 7, 82, 0.3), 6px 6px 12px rgba(243, 127, 197, 0.3),
    1px 4px 5px rgba(229, 105, 164, 0.3), 0px 4px 60px #180023;
}

h2 {
  font-size: clamp(25px, 3vw, 50px);
  text-align: right;
  color: #fdd7f2;
  text-shadow: 0px -2px 4px rgba(229, 105, 164, 0.5), 0px 0px 8px rgba(216, 7, 82, 0.5), 3px 3px 6px rgba(243, 127, 197, 0.5),
    1px 2px 3px rgba(229, 105, 164, 0.5), 0px 2px 15px #180023;
}

h3 {
  text-align: left;
}

h3, h4, h5 {
  font-size: clamp(25px, 2.5vw, 50px);
  color: #fdd7f2;
  font-weight: 900;
  line-height: clamp(.75rem, 4vh, 40px);
  margin-top: 1rem;
}

h4 {
  text-align: right;
  color: #f361c0;
}

p {
  font-size: clamp(16px, 2vh, 32px);
  color: #e569a4;
}

a {
  cursor: pointer;
  color: #e569a4;
}

p:first-child:first-letter {
  color: #fdd7f2;
  font-size: clamp(4em, 5em, 6em);
  font-weight: 900;
  font-style: italic;
  initial-letter: 3;
  line-height: 1;
  margin: 0 2rem 0 0;
  float: left;
}

.list {
  margin-top: 2rem;
  text-align: center;
}

.production{ 
  margin-top: 3rem;
}

ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

li {
  display: grid;
  grid-template-columns: 4.5fr 1fr 4.5fr;
  gap: 2rem;
  align-items: center;
}


.paragraph-text {
  columns: 3;
  margin-top: 2rem;
}



@media only screen and (max-width: 736px) {

  .root {
    top: -5vh;
    height: auto;
    padding-block: 1.5em;
    box-shadow: 0px 0px 60px rgba(17, 0, 17, 1), 0px 0px 60px rgba(17, 0, 17, 1), 0px 0px 60px rgba(17, 0, 17, 1);
    transition: transform, visibility, 0.6s ease-in-out;
  }

  h1 {
    font-size: 3em;
  }

  h2 {
    font-size: 2em;
    text-align: center;
  }

  h3 {
    margin-block: 1.5rem;
  }

  h3, h4, h5 {
    font-size: 1.3em;
    text-align: center;
    line-height: 0;
  }

  p {
    font-size: 1em;
  }


  .paragraph-text {
    columns: 1;
  }
  
  li {
    grid-template-columns: 1fr;
    gap: 0;
  }
}