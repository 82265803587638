@import url('https://fonts.googleapis.com/css2?family=Oooh+Baby&family=Roboto:ital,wght@0,400;1,900&display=swap');

.root {
  --color-light_pink: #fdd7f2;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  
  /* visibility of elements - dev purpose */
  /* background: rgba(60, 165, 230, 0.2);  */
}

/* visibility of elements - dev purpose */
h1, h2, h3, h4, p, ul, li, a, button {
  /* border: .5px solid red; */
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2,
button {
  font-family: 'Oooh Baby', cursive;
}

h3,
h4,
h5,
p,a {
  font-family: 'Roboto', sans-serif;
}

