.root {
  position: absolute;
  border: 2px solid rgba(253, 215, 242, 1);
  border-radius: 20px;
  left: 6vw;
  top: 7.5vh;
  right: 6vw;
  bottom: 7.5vh;
}

@media screen and (max-width:960px) {
  h3 {
    text-align: left;
  }
  
}