.App {
  position: relative;
  background-color: #180023;
  width: 100vw;
  min-height: 100vh;
}


.background {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: fill;
  mix-blend-mode: hard-light;
}



@media screen and (max-width:768px) {
  .App {
    min-height: 300vh;
  }
  
}