@import url('https://fonts.googleapis.com/css2?family=Oooh+Baby&display=swap');

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5em;
  text-align: center;
}

button {
  font-size: clamp(2rem, 4vw, 3.5rem);
  text-align: center;
  text-decoration: none;
  color: #fdd7f2;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-shadow: 0px -5px 17px #e569a4, 0px 0px 33px #d80752, 6px 6px 12px #f37fc5,
    1px 4px 5px #e569a4, 0px 4px 60px #180023;
}

@media (max-width: 768px) {
  button {
    font-size: clamp(0.6rem, 7vw, 2rem);
    margin: 0 max(0.7rem, 20px);
  }

  .root {
    position: sticky;
    top: 0;
    flex-direction: row;
    order: -3;
    gap: 0;
  }
}