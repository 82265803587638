.root {
  position: relative;
  padding: 20px;
  height: 100%;
}

.grid {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
  height: 100%;
  align-items: center;
  gap: 10px;
}



@media (max-width:768px) {
  .grid {
    grid-template-columns: 1fr;
    grid-template-rows: 50vmax 10vmax 10vmax auto;
  }
}