.root {
  position: relative;
  width: 100%;
  height: 100%;
  grid-column: span 2;
  grid-row: span 2;
}

img {
  object-fit: fill;
}

@media (max-width:768px) {
  .root {order: -4;
    grid-column: span 1;
    overflow: hidden;

  }
}
