.root {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  overflow: hidden;
}

.heart {
  position: absolute;
  left: 12%;
  width: 80%;
  height: 80%;
  fill:#9a088e;
  stroke-width: 3px;
}

.heartShape {
  position: absolute;
  left: 12%;
  width: 80%;
  height: 80%;
  stroke: #a8367a;
  stroke-width: 3px;
  transform: scale(0.8);
  
}

@media (prefers-reduced-motion: no-preference) {
  .heart {
    animation: heart-pulse 1s .5s infinite;
  }

  .heartShape {
    animation: heart-shape-pulse 3s infinite;
  }
}

.delay2s {
  animation-delay: 1s;
}

.delay4s {
  animation-delay: 2s;
}

@keyframes heart-shape-pulse {
  
  10% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: scale(2);  
  }
}

@keyframes heart-pulse {
  10% {
    transform: scale(1.2);
  }
  30% {
    transform: scale(1)
  }
}